import React from 'react';

import { Field, SelectField } from 'components';

const COMPANY_SIZE_OPTIONS = [
  'Small (1 - 50 employees)',
  'Medium (51 - 250 employees)',
  'Large (251+ employees)'
];

const options = COMPANY_SIZE_OPTIONS.map(group => ({
  key: group,
  value: group,
  label: group
}));

const CompanySizeSelect = ({ name, ...rest }) => (
  <Field
    name={name}
    component={SelectField}
    isClearable
    options={options}
    {...rest}
  />
);

export default CompanySizeSelect;
