import { Fragment } from 'react';

import { Label } from 'semantic-ui-react';
import { getOperationSign } from 'entities/PriceSerie';

export default function LabelField({ priceSerie }) {
  return (
    <div style={{ display: 'flex', gap: '2px', flexWrap: 'wrap' }}>
      <Label horizontal color="teal">
        {priceSerie.display_as}
      </Label>

      {priceSerie.components.map((componentPrice, index) => (
        <Fragment key={componentPrice.id}>
          <Label horizontal circular>
            {index === 0 ? '=' : getOperationSign(componentPrice.operation)}
          </Label>
          <Label horizontal color="green">
            {componentPrice.display_as}
          </Label>
        </Fragment>
      ))}
    </div>
  );
}
