import _ from 'lodash';
import React, { Fragment } from 'react';
import { Header, Table } from 'semantic-ui-react';

import { Flex } from 'components';

import { usePriceSerieCombinationPreview } from './sdk';
import { formatDateForFrequency, formatDimension } from './utils';

import DataPointContainer from '../DataPointContainer';
import QuickAccessConversionControlFields from '../QuickAccessConversionControlFields';
import { areAllSelectedPriceSet } from '../PriceSeriesCombineForm/utils';

function TableWrapper({ children }) {
  return (
    <div
      style={{
        maxHeight: '350px',
        overflowY: 'auto',
        display: 'block',
        width: '100%',
        border: '1px solid rgba(34, 36, 38, 0.15)',
        borderRadius: '5px'
      }}>
      {children}
    </div>
  );
}

export default function ResultsPreviewTable({
  values,
  setFieldValue,
  productSlug
}) {
  const { selected_prices: selectedPrices } = values;

  const {
    dataPoints,
    conversionStatistics,
    isLoading,
    errors
  } = usePriceSerieCombinationPreview({
    productSlug,
    values,
    shouldFetch: _.every(selectedPrices?.map(({ id }) => !_.isNil(id)))
  });

  const { inputs = {}, output } = conversionStatistics;
  const resultDimension = formatDimension(output);

  // 2 -> Date and Computed data point
  // 2 * selectedPrices.length -> Original and Converted data points for each selected price serie
  const tableColSpan = 2 + 2 * selectedPrices.length;
  const missingSelectedPrices = !areAllSelectedPriceSet(values);

  return (
    <Flex alignStart style={{ flexDirection: 'column', width: '100%' }}>
      <QuickAccessConversionControlFields
        values={values}
        setFieldValue={setFieldValue}
        productSlug={productSlug}
        disabled={isLoading}
      />
      <Header style={{ paddingLeft: '7px' }} as="h5">
        Results Preview Table
      </Header>
      <TableWrapper>
        <Table celled striped structured>
          <Table.Header style={{ position: 'sticky', top: 0 }}>
            <Table.Row>
              <Table.HeaderCell rowSpan="2">Date</Table.HeaderCell>
              {selectedPrices?.map(({ id: priceSerieId }, index) => (
                <Table.HeaderCell key={index} colSpan="2">
                  {isLoading ? 'Loading...' : inputs[priceSerieId]?.display_as}
                </Table.HeaderCell>
              ))}
              <Table.HeaderCell rowSpan="2">
                Computed data point {resultDimension}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              {selectedPrices?.map(({ id: priceSerieId }, index) => (
                <Fragment key={index}>
                  <Table.HeaderCell>
                    Original {formatDimension(inputs[priceSerieId])}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Converted {resultDimension}
                  </Table.HeaderCell>
                </Fragment>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {isLoading || missingSelectedPrices ? (
              <>
                {Array.from({ length: 6 }).map((_, index) => (
                  <Table.Row key={index}>
                    {Array.from({ length: tableColSpan }).map((_, index) => (
                      <Table.Cell key={index}>
                        {missingSelectedPrices ? 'N/A' : 'Loading...'}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </>
            ) : (
              <>
                {!_.isEmpty(errors) &&
                  errors.map((error, index) => (
                    <Table.Row key={index}>
                      <Table.Cell
                        colSpan={tableColSpan}
                        textAlign="center"
                        style={{ padding: '60px 30px' }}>
                        <Header as="h3" color="red">
                          {error.message}
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                {_.isEmpty(errors) &&
                  dataPoints.map((combinedDataPoint, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>
                        {formatDateForFrequency(
                          combinedDataPoint.date,
                          values.frequency
                        )}
                      </Table.Cell>
                      {selectedPrices?.map(({ id: priceSerieId }, index) => (
                        <Fragment key={index}>
                          <Table.Cell>
                            <DataPointContainer
                              values={_.get(
                                combinedDataPoint,
                                `inputs[${priceSerieId}].original`
                              )}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <DataPointContainer
                              values={_.get(
                                combinedDataPoint,
                                `inputs[${priceSerieId}].converted`
                              )}
                            />
                          </Table.Cell>
                        </Fragment>
                      ))}

                      <Table.Cell>
                        <DataPointContainer values={combinedDataPoint.output} />
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </>
            )}
          </Table.Body>
        </Table>
      </TableWrapper>
    </Flex>
  );
}
