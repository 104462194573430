import { useCallback } from 'react';
import { Button, Icon, Segment, Table } from 'semantic-ui-react';
import _ from 'lodash';
import { FieldArray } from 'formik';

import { ChoiceToggle, UniSelect } from 'components';
import { Link } from 'components';
import { pageUrl } from 'config/routes';

import { PriceCombinationOperations } from 'entities/PriceSerie';
import { areAllSelectedPriceSet } from '../PriceSeriesCombineForm/utils';
import { PriceSerieDetail } from 'pages/PriceSerie';

function PriceSerieLink({ productSlug, priceSerie }) {
  return (
    <Link
      url={pageUrl(PriceSerieDetail, {
        productSlug: productSlug,
        priceSeriesId: priceSerie.id
      })}>
      {priceSerie.display_as}
    </Link>
  );
}

function PriceSerieTableRow({
  setFieldValue,
  index,
  values,
  productSlug,
  onRemove,
  disabled = false,
  disableRemove = false,
  readOnly = false,
  hideOperation = false,
  excludeOptionsIds = []
}) {
  const { selected_prices: selectedPrices } = values;

  return (
    <Table.Row>
      <Table.Cell>
        {!hideOperation && (
          <ChoiceToggle
            disabled={disabled || readOnly}
            leftOption={{
              predicate:
                values.selected_prices[index]?.operation ===
                PriceCombinationOperations.ADD,
              onClick: () =>
                setFieldValue(
                  `selected_prices[${index}].operation`,
                  PriceCombinationOperations.ADD
                ),
              text: '+'
            }}
            rightOption={{
              predicate:
                values.selected_prices[index]?.operation ===
                PriceCombinationOperations.SUB,
              onClick: () =>
                setFieldValue(
                  `selected_prices[${index}].operation`,
                  PriceCombinationOperations.SUB
                ),
              text: '-'
            }}
          />
        )}
      </Table.Cell>

      {readOnly ? (
        <>
          <Table.Cell>
            <PriceSerieLink
              productSlug={productSlug}
              priceSerie={selectedPrices[index]}
            />
          </Table.Cell>
          <Table.Cell>{selectedPrices[index].product}</Table.Cell>
          <Table.Cell>{selectedPrices[index].category}</Table.Cell>
          <Table.Cell>{selectedPrices[index].format}</Table.Cell>
          <Table.Cell>{selectedPrices[index].frequency}</Table.Cell>
        </>
      ) : (
        <Table.Cell>
          <UniSelect
            required
            isClearable={false}
            name={`selected_prices[${index}].id`}
            source="priceSeriesAll"
            excludeOptionsIds={excludeOptionsIds}
            params={{
              product_type: productSlug,
              exclude_combined_prices: true
            }}
            disabled={disabled}
          />
        </Table.Cell>
      )}

      <Table.Cell>
        <Button
          disabled={disableRemove || readOnly || disabled}
          onClick={onRemove}
          icon="remove"
          color="red"
        />
      </Table.Cell>
    </Table.Row>
  );
}

export default function PriceSelectionFieldArray({
  values,
  setFieldValue,
  productSlug,
  disabled = false,
  readOnly = false
}) {
  const buildExcludeOptionsIds = useCallback(
    selectedPrice =>
      _.map(values.selected_prices, priceSerie =>
        priceSerie.id === selectedPrice.id ? null : priceSerie.id
      ),
    [values.selected_prices]
  );

  const shouldDisableRemoveButton = useCallback(
    index => {
      if (index === 0) {
        return true;
      }

      if (values.selected_prices.length <= 2) {
        return true;
      }

      return false;
    },
    [values.selected_prices.length]
  );

  const bottomRowColSpan = readOnly ? 6 : 3;

  return (
    <Segment style={{ width: '100%' }}>
      <Table celled basic="very" singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell collapsing>Operation</Table.HeaderCell>
            <Table.HeaderCell>Price Series</Table.HeaderCell>
            {readOnly ? (
              <>
                <Table.HeaderCell>Product</Table.HeaderCell>
                <Table.HeaderCell>Category</Table.HeaderCell>
                <Table.HeaderCell>Format</Table.HeaderCell>
                <Table.HeaderCell>Frequency</Table.HeaderCell>
              </>
            ) : null}
            <Table.HeaderCell collapsing>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <FieldArray
            name="selected_prices"
            render={({ push, remove }) => (
              <>
                {values.selected_prices.map((selectedPrice, index) => (
                  <PriceSerieTableRow
                    key={index}
                    index={index}
                    hideOperation={index === 0}
                    onRemove={() => remove(index)}
                    values={values}
                    setFieldValue={setFieldValue}
                    productSlug={productSlug}
                    disabled={disabled}
                    disableRemove={shouldDisableRemoveButton(index)}
                    readOnly={readOnly}
                    excludeOptionsIds={buildExcludeOptionsIds(selectedPrice)}
                  />
                ))}
                <Table.Row>
                  <Table.Cell colSpan={bottomRowColSpan} textAlign="center">
                    <Button
                      color="blue"
                      onClick={() =>
                        push({
                          id: null,
                          operation: PriceCombinationOperations.ADD
                        })
                      }
                      disabled={!areAllSelectedPriceSet(values) || readOnly}>
                      <Icon name="add" />
                      Add another price series
                    </Button>
                  </Table.Cell>
                </Table.Row>
              </>
            )}
          />
        </Table.Body>
      </Table>
    </Segment>
  );
}
