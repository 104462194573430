import _ from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';

import { Header, Icon, Segment } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import { PRICE_SERIE_DETAILS_URL } from 'sdk/urls';

import { withLoggedUser, withPermissions } from 'hooks';

import { Page, Button, Flex, Link } from 'components';
import { notifyErrors } from 'utils/notifications';

import { PERMISSIONS, DisableableElement, hasPermission } from 'permissions';

import { PriceSeriesUpdateForm } from './components';

import { priceSerieDetail } from './sdk';
import { pageUrl } from 'config/routes';
import { PriceSerieCombine } from '..';

function PriceSerieDetail({ user, crumbs }) {
  const { productSlug, priceSeriesId } = useParams();
  const [priceSeries, setPriceSeries] = useState(null);

  const fetchPriceSeries = useCallback(async () => {
    const { data, errors, success } = await priceSerieDetail(priceSeriesId);

    if (success) {
      setPriceSeries(data);
    } else {
      notifyErrors(errors);
    }
  }, [priceSeriesId]);

  useEffect(() => {
    fetchPriceSeries();
  }, [fetchPriceSeries]);

  return (
    <>
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Flex spaceBetween>
                <Header as="h1">Price Series Detail</Header>

                <div style={{ display: 'flex', gap: '5px' }}>
                  {priceSeries && !priceSeries.is_combined && (
                    <Button
                      as="a"
                      color="blue"
                      href={`${pageUrl(PriceSerieCombine, {
                        productSlug
                      })}?mainPriceSerie=${priceSeriesId}`}
                      target="_blank">
                      <Icon name="add" />
                      Combine
                    </Button>
                  )}
                  {priceSeries?.kallindex_url && (
                    <Link url={priceSeries.kallindex_url}>
                      <Button>View in Kallindex</Button>
                    </Link>
                  )}
                  <Link
                    url={PRICE_SERIE_DETAILS_URL(_.get(priceSeries, 'slug'))}>
                    <Button>View in Website</Button>
                  </Link>
                </div>
              </Flex>
              {!_.isNil(priceSeries) && (
                <DisableableElement
                  disabled={!hasPermission(user, PERMISSIONS.CHANGE_PRICES)}>
                  <PriceSeriesUpdateForm
                    priceSeries={priceSeries}
                    fetchPriceSeries={fetchPriceSeries}
                  />
                </DisableableElement>
              )}
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    </>
  );
}

export default withLoggedUser(
  withPermissions([PERMISSIONS.CREATE_PRICES])(PriceSerieDetail)
);
