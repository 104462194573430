import React from 'react';
import { Table } from 'semantic-ui-react';
import { YesNo } from 'components';

const DEFAULT_VARIABLES = [
  ['{TITLE}', 'Client Title', true],
  ['{FIRST_NAME}', 'Client First Name', true],
  ['{LAST_NAME}', 'Client Last Name', true],
  ['{EMAIL}', 'Client E-mail', true],
  ['{{JOB_TITLE}}', 'Client Job Title', true],
  ['{COMPANY}', 'Client Company', true],
  ['{PASSWORD}', 'Client Password', true],
  ['{CURRENT_DATE}', 'Current date', true],
  ['{CURRENT_YEAR}', 'Current year', true],
  ['{ACCOUNT_SIGNATURE}', 'Account Manager Signature', false],
  ['{TERRITORY_ACCOUNT_SIGNATURE}', 'Sales Territory Manager Signature', false],
  ['{UNSUBSCRIBE_URL}', 'Unsubscribe Link', true],
  ['{WEB_VIEW_URL}', 'Web View Link', false]
];

const EmailVariables = ({ defaultData = null }) => {
  const data = defaultData || DEFAULT_VARIABLES;

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Variable</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell width={1} style={{ whiteSpace: 'nowrap' }}>
            Use in Subject
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data.map((item, index) => (
          <Table.Row key={index}>
            <Table.Cell>{item[0]}</Table.Cell>
            <Table.Cell>{item[1]}</Table.Cell>
            <Table.Cell>
              <YesNo value={item[2]}></YesNo>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default EmailVariables;
