import _ from 'lodash';
import { useEffect } from 'react';
import { withFormik } from 'formik';
import { Dimmer, DimmerDimmable, Form, Segment } from 'semantic-ui-react';

import { prepareFormData, handleFormErrors } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';

import { pageUrl } from 'config/routes';

import { PriceCombinationOperations } from 'entities/PriceSerie';

import { usePriceSerieDetail } from 'pages/PriceSerie/Detail/sdk';
import CreatePriceFormFields from 'pages/PriceSerie/Create/components/PriceSeriesCreateForm/FormFields';
import { DEFAULT_INITIAL_VALUES } from 'pages/PriceSerie/Create/components/PriceSeriesCreateForm/utils';
import { PriceSerieList } from 'pages/PriceSerie';

import {
  ErrorsAndCreateButtonInRow as FormActionsRow,
  UnsavedFormPrompt,
  Header
} from 'components';
import { PriceDataSources } from 'components/UniSelect/constants';

import PreviewResultsModalButton from '../PreviewResultsModalButton';
import PriceSelectionFieldArray from '../PriceSelectionFieldArray';

import { priceSeriesCombine } from './sdk';
import { validationSchema } from './utils';

function PriceSeriesCombineForm({
  isSubmitting,
  handleSubmit,
  values,
  dirty,
  touched,
  errors,
  setFieldValue,
  productSlug
}) {
  const mainPriceId = values.selected_prices[0]?.id;

  const { priceSerie: mainPrice } = usePriceSerieDetail({
    id: mainPriceId,
    shoudFetch: !_.isNil(mainPriceId)
  });

  const areDetailFieldsDisabled = !mainPriceId;

  useEffect(() => {
    if (mainPrice) {
      setFieldValue('format', mainPrice.format);
      setFieldValue('unit', mainPrice.unit);
      setFieldValue('frequency', mainPrice.frequency);
      setFieldValue('currency', mainPrice.currency);
      setFieldValue('scale', mainPrice.scale);
      setFieldValue('decimalPlaces', mainPrice.decimal_places);
    }
  }, [setFieldValue, mainPrice]);

  return (
    <Form>
      <UnsavedFormPrompt when={dirty && !isSubmitting} />
      <FormActionsRow
        errors={errors}
        touched={touched}
        onClick={handleSubmit}
        disabled={isSubmitting}
        additonalActions={
          <PreviewResultsModalButton
            values={values}
            setFieldValue={setFieldValue}
            productSlug={productSlug}
          />
        }
      />

      <PriceSelectionFieldArray
        values={values}
        setFieldValue={setFieldValue}
        productSlug={productSlug}
      />

      <Header as="h3">Combined price details</Header>
      <DimmerDimmable id="asd" as={Segment} dimmed={areDetailFieldsDisabled}>
        <CreatePriceFormFields
          values={values}
          setFieldValue={setFieldValue}
          productSlug={productSlug}
          disabled={areDetailFieldsDisabled}
          fieldsConfig={{ priceDataSource: { disabled: true } }}
        />
        <Dimmer
          active={areDetailFieldsDisabled}
          verticalAlign="top"
          style={{ backgroundColor: 'rgba(248, 248, 249, 0.7)', zIndex: 0 }}>
          <Header as="h2">Select price series to combine</Header>
        </Dimmer>
      </DimmerDimmable>

      <FormActionsRow
        errors={errors}
        touched={touched}
        onClick={handleSubmit}
        disabled={isSubmitting}
        additonalActions={
          <PreviewResultsModalButton
            values={values}
            setFieldValue={setFieldValue}
            productSlug={productSlug}
          />
        }
      />
    </Form>
  );
}

export default withFormik({
  mapPropsToValues: ({ mainPriceSerie }) => ({
    selected_prices: [
      {
        id: mainPriceSerie || null,
        operation: PriceCombinationOperations.ADD
      },
      { id: null, operation: PriceCombinationOperations.ADD }
    ],
    ...DEFAULT_INITIAL_VALUES,
    priceDataSource: PriceDataSources.Combined
  }),
  handleSubmit: async (values, { setSubmitting, setFieldError, props }) => {
    const { productSlug, history } = props;

    const selectedPrices = values.selected_prices.map(({ id }) => id);
    const operations = values.selected_prices.map(({ operation }) => operation);

    const data = {
      ...prepareFormData({
        ...values,
        selected_prices: selectedPrices,
        operations
      }),
      regions: _.without([values.region, values.region2], null)
    };

    setSubmitting(true);

    const { errors, success } = await priceSeriesCombine(productSlug, data);

    if (success) {
      notifySuccess('Price series created.', {
        onOpen: history.push(pageUrl(PriceSerieList, { productSlug }))
      });
      return;
    }

    handleFormErrors(errors, setFieldError);
    setSubmitting(false);
  },
  validationSchema: validationSchema
})(PriceSeriesCombineForm);
