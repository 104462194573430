import * as yup from 'yup';

import { validationSchema as priceSerieCreateValidationSchema } from 'pages/PriceSerie/Create/components/PriceSeriesCreateForm/utils';

export const validationSchema = priceSerieCreateValidationSchema.shape({
  selected_prices: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required('This field is required.'),
        operation: yup.string().required('This field is required.')
      })
    )
    .test({
      message: 'At least two price series must be selected.',
      test: arr => arr.length >= 2
    })
});

export const areAllSelectedPriceSet = values => {
  const { selected_prices = [] } = values;

  return (
    selected_prices.map(priceSerie => priceSerie.id).filter(Boolean).length ===
    selected_prices.length
  );
};
