import React, { useCallback, useEffect, useState } from 'react';
import { Header, Icon, Label, Loader, Modal, Table } from 'semantic-ui-react';
import _ from 'lodash';

import { Flex } from 'components';
import { partialTrialPreferenceDetails } from './sdk';
import { notifyErrors } from 'utils/notifications';
import { formatDateTime } from 'utils';
import {
  PMTrialPreferencesTable,
  SteelTrialPreferencesTable
} from './components';

import styles from './styles.module.css';

const PartialTrialPreferenceDetailsModal = ({ preferenceId, close }) => {
  const [loading, setLoading] = useState(true);
  const [preference, setPreference] = useState(null);
  const [modalColor, setModalColor] = useState('red');
  const [trialStep, setTrialStep] = useState('N/A');

  const fetchPartialTrialPreference = useCallback(async () => {
    const { data, errors, success } = await partialTrialPreferenceDetails(
      preferenceId
    );

    if (success) {
      setPreference(data);
    } else {
      notifyErrors(errors);
    }
    setLoading(false);
  }, [preferenceId]);

  useEffect(() => {
    fetchPartialTrialPreference();
  }, [fetchPartialTrialPreference]);

  useEffect(() => {
    if (!preference) return;

    if (preference.status === 'Finished') {
      setModalColor('green');
      return;
    }

    const currentStep = preference.current_step;
    const hasFinishedCommonTrial =
      currentStep.split('/')[0] === 'advanced-demographics';

    setModalColor(hasFinishedCommonTrial ? 'orange' : 'red');

    const parts = currentStep.split('/').map(part =>
      part
        .split('-')
        .map(_.startCase)
        .join(' ')
    );

    setTrialStep(parts.join(': '));
  }, [preference]);

  return (
    <Modal open={true} size="large" style={{ width: '1000px' }}>
      <Modal.Header>
        <Flex spaceBetween>
          <Flex flexStart style={{ alignItems: 'flex-start' }}>
            <Header as="h2" style={{ margin: 0 }}>
              Free Trial Details
            </Header>
            {preference && (
              <Label
                basic
                color={modalColor}
                style={{ marginLeft: '15px', textTransform: 'uppercase' }}>
                {preference.status === 'Finished' ? 'complete' : 'incomplete'}
              </Label>
            )}
          </Flex>
          <Icon name="close" onClick={close} />
        </Flex>
        {preference && (
          <Header as="h3" style={{ marginTop: '10px' }} color={modalColor}>
            {preference.email}
          </Header>
        )}
      </Modal.Header>
      <Modal.Content>
        {loading && <Loader active inline="centered" />}
        {!loading && (
          <Flex spaceBetween style={{ alignItems: 'stretch' }}>
            <Table
              definition
              collapsing
              className={styles.tableStyle}
              style={{ marginBottom: '0' }}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell width={5}>Attempt time</Table.Cell>
                  <Table.Cell>
                    {formatDateTime(preference.created_at)}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Last updated at</Table.Cell>
                  <Table.Cell>
                    {formatDateTime(preference.updated_at)}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Current step</Table.Cell>
                  <Table.Cell>{trialStep}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Names</Table.Cell>
                  <Table.Cell>
                    {(preference.first_name !== '' ||
                      preference.last_name !== '') && (
                      <>
                        {preference.first_name} {preference.last_name}
                      </>
                    )}
                    {preference.first_name === '' &&
                      preference.last_name === '' &&
                      'N/A'}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Referrals</Table.Cell>
                  <Table.Cell>
                    {preference.referrals.length === 0 && 'N/A'}
                    {preference.referrals.length !== 0 && (
                      <ul style={{ paddingLeft: '10px' }}>
                        {preference.referrals.map((referral, index) => (
                          <li key={index}>{referral}</li>
                        ))}
                      </ul>
                    )}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Job title</Table.Cell>
                  <Table.Cell>{preference.job_title || 'N/A'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Company name</Table.Cell>
                  <Table.Cell>{preference.company_name || 'N/A'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Company size</Table.Cell>
                  <Table.Cell>{preference.company_size || 'N/A'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Phone</Table.Cell>
                  <Table.Cell>{preference.phone || 'N/A'}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Country</Table.Cell>
                  <Table.Cell>{preference.country || 'N/A'}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            {preference.subscription_type === 'Kallanish Steel' && (
              <SteelTrialPreferencesTable preference={preference} />
            )}
            {preference.subscription_type === 'Kallanish Power Materials' && (
              <PMTrialPreferencesTable preference={preference} />
            )}
          </Flex>
        )}
      </Modal.Content>
    </Modal>
  );
};

export default PartialTrialPreferenceDetailsModal;
