import { Icon } from 'semantic-ui-react';

import { Tooltip } from 'components';

export default function CombinedPriceInfoTooltip() {
  return (
    <Tooltip
      content={
        <p>
          This is a{' '}
          <span style={{ fontWeight: 'bold' }}>combined price series</span>. New
          data points will be automatically synced once values for its component
          prices are <span style={{ fontWeight: 'bold' }}>published</span>.
        </p>
      }
      on="hover"
      trigger={<Icon name="info circle" color="grey" />}
    />
  );
}
