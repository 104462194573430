import { Icon, Popup, Table } from 'semantic-ui-react';

import { Flex, YesNo } from 'components';
import { formatDate } from 'utils';

import styles from '../../styles.module.css';
import {
  KALLANISH_STEEL,
  KALLANISH_STEEL_LIVE,
  WEEKLY_STEEL_PREMIUM,
  WEEKLY_STEEL_STANDARD
} from 'domainConstants';

const SteelTrialPreferencesTable = ({ preference }) => {
  const baseSubscriptionMap = {
    [WEEKLY_STEEL_PREMIUM]: WEEKLY_STEEL_STANDARD,
    [KALLANISH_STEEL_LIVE]: KALLANISH_STEEL
  };

  const upsellSubscription = preference.all_services[0];
  const baseSubscription =
    baseSubscriptionMap[upsellSubscription] || upsellSubscription;
  const otherServices = preference.all_services.slice(1);

  return (
    <Table
      definition
      collapsing
      className={styles.tableStyle}
      style={{ marginTop: '0' }}>
      <Table.Row>
        <Table.Cell width={5}>Subscription Type</Table.Cell>
        <Table.Cell>
          <Flex style={{ justifyContent: 'start' }}>
            <span>{upsellSubscription}</span>
            {baseSubscription !== upsellSubscription && (
              <Popup
                content={`Upgraded from ${baseSubscription}`}
                trigger={
                  <Icon
                    name="info circle large"
                    style={{ marginLeft: '5px' }}
                  />
                }
                style={{ marginLeft: '-5px' }}
              />
            )}
          </Flex>
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Upsell Subscription Type</Table.Cell>
        <Table.Cell>
          <YesNo
            value={baseSubscription !== upsellSubscription}
            style={{ justifyContent: 'start' }}
          />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Frequency</Table.Cell>
        <Table.Cell>{preference.steel_frequency || 'N/A'}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Group articles by</Table.Cell>
        <Table.Cell>{preference.group_articles_by || 'N/A'}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Other Services</Table.Cell>
        <Table.Cell>
          {otherServices.length === 0 && 'N/A'}
          {otherServices.length !== 0 && (
            <ul style={{ paddingLeft: '10px' }}>
              {otherServices.map((service, index) => (
                <li key={index}>{service}</li>
              ))}
            </ul>
          )}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Start Date</Table.Cell>
        <Table.Cell>{formatDate(preference.start_date)}</Table.Cell>
      </Table.Row>
    </Table>
  );
};

export default SteelTrialPreferencesTable;
