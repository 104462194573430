import _ from 'lodash';
import { requestSdk, get } from 'sdk';
import { BASE_URL } from 'sdk/urls';
import { PriceSerieStatus } from 'pages/PriceSerie/constants';

import {
  PRICE_SERIES_FORMATS,
  PRICE_SERIES_FREQUENCIES,
  PRICE_SERIES_SCALES,
  EMAIL_TYPES,
  PROMO_CODES_DISCOUNT_TYPES,
  WEEKLY_SEASM_TAGS,
  CONTENT_SECTION_COLUMN,
  CONTENT_SECTION_TYPE,
  ARTICLE_GROUPING,
  ALLOWED_FEEDBACK_PAGES,
  FEEDBACK_DISPLAY_OPTIONS,
  PRICE_SERIES_OPEN_ACTION_TYPES,
  PriceDataSources
} from './constants';

import CombinedPriceSeriesLabelField from './cmponents/CombinedPriceSeries/LabelField';

export const Choices = {
  get: props => Choices[props.source].data(props),
  _getData: async props => {
    const source = Choices[props.source];

    const { data, success } = await requestSdk(() =>
      get(source.url(props), props.params ? props.params : {})
    );
    let choices = [];

    const getLabel = item => {
      if (_.has(source, 'labelFields')) {
        return _.join(
          _.map(source.labelFields, label => _.get(item, label)),
          ' - '
        );
      }

      if (_.isFunction(source.labelField)) {
        return source.labelField(item);
      }

      return _.get(item, source.labelField);
    };

    if (success) {
      choices = data.map(item => {
        return {
          key: _.get(item, source.keyField),
          value: _.get(item, source.valueField),
          label: getLabel(item),
          extraData: _.get(item, source.extraDataFields, null)
        };
      });
    }
    const { apiSuccessCallback } = props;

    if (_.isFunction(apiSuccessCallback)) {
      apiSuccessCallback(data);
    }

    return choices;
  },
  articleGroups: {
    url: props => `${BASE_URL}/articles/${props.productSlug}/groups/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'name',
    data: props => Choices._getData(props)
  },
  articleSections: {
    url: props => `${BASE_URL}/articles/${props.productSlug}/sections/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'name',
    data: props => Choices._getData(props)
  },
  articleTopics: {
    url: props => `${BASE_URL}/articles/${props.productSlug}/topics/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    data: props => Choices._getData(props)
  },
  articleLabels: {
    url: props => `${BASE_URL}/articles/${props.productSlug}/article-labels/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    data: props => Choices._getData(props)
  },
  articleStatus: {
    data: async () => {
      const { data, success } = await requestSdk(() =>
        get(`${BASE_URL}/articles/statuses/`)
      );
      if (success) {
        let choices = data.map(status => {
          return {
            key: status,
            value: status,
            label: status
          };
        });
        return choices;
      }
    }
  },
  regions: {
    url: props => `${BASE_URL}/editorials/regions/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    data: props => Choices._getData(props)
  },
  priceSerieProducts: {
    url: props =>
      `${BASE_URL}/prices/series/${props.productSlug}/price-serie-products/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    data: props => Choices._getData(props)
  },
  priceSerieCategoriesForFilters: {
    url: props =>
      `${BASE_URL}/prices/series/v2/${props.productSlug}/price-serie-categories/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    data: props => Choices._getData(props)
  },
  priceSerieCategories: {
    url: props =>
      `${BASE_URL}/prices/series/v2/${props.productSlug}/price-serie-categories/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    data: props => Choices._getData(props)
  },
  priceSerieProductGroups: {
    url: props => `${BASE_URL}/cms/price-series-products/product-groups/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    data: props => Choices._getData(props)
  },
  priceSerieRegions: {
    url: props => `${BASE_URL}/prices/series/price-series-regions/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    data: props => Choices._getData(props)
  },
  priceSerieIncoTerms: {
    url: props => `${BASE_URL}/prices/series/price-series-inco-terms/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    data: props => Choices._getData(props)
  },
  priceSerieUnits: {
    url: props => `${BASE_URL}/prices/series/price-series-units/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    data: props => Choices._getData(props)
  },
  priceSerieCurrencies: {
    url: props => `${BASE_URL}/prices/series/price-series-currencies/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'code',
    data: props => Choices._getData(props)
  },
  priceSerieFormats: {
    data: () =>
      PRICE_SERIES_FORMATS.map(option => ({
        key: option,
        value: option,
        label: option
      }))
  },
  priceSerieFrequencies: {
    data: () =>
      PRICE_SERIES_FREQUENCIES.map(option => ({
        key: option,
        value: option,
        label: option
      }))
  },
  priceSerieScales: {
    data: () =>
      PRICE_SERIES_SCALES.map(option => ({
        key: option,
        value: option,
        label: option
      }))
  },
  priceSerieDataSources: {
    data: props => {
      const { disabledChoices = [] } = props;

      return PriceDataSources.choices.map(option => ({
        key: option,
        value: option,
        label: option,
        isDisabled: disabledChoices.includes(option)
      }));
    }
  },
  priceSeries: {
    url: props =>
      `${BASE_URL}/prices/series/v2/?paginate=False` +
      (props.productSlug ? `&product_type=${props.productSlug}` : ''),
    keyField: 'id',
    valueField: 'id',
    labelField: 'display_as',
    data: props => Choices._getData(props)
  },
  priceSeriesAll: {
    url: props => `${BASE_URL}/prices/series/v2/?paginate=False`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'display_as',
    data: props => Choices._getData(props)
  },
  priceSeriesChoicesOnly: {
    url: props =>
      `${BASE_URL}/prices/series/v2/${props.productSlug}/priceseries-options/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'display_as',
    data: props => Choices._getData(props)
  },
  recipientGroups: {
    url: props => `${BASE_URL}/common/${props.productSlug}/recipient-groups/`,
    keyField: 'id',
    valueField: 'id',
    labelFields: ['subscription', 'article_grouping', 'subscription_status'],
    data: props => Choices._getData(props)
  },
  subscriptionsForProduct: {
    url: props =>
      `${BASE_URL}/editorials/subscriptions/for-product/${props.productSlug}/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    data: props => Choices._getData(props)
  },
  subscriptionStatusses: {
    url: props => `${BASE_URL}/editorials/subscription-statuses/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    data: props => Choices._getData(props)
  },
  emailTypes: {
    data: () =>
      EMAIL_TYPES.map(option => ({
        key: option,
        value: option,
        label: option
      }))
  },
  newsletterSectionTypes: {
    data: () =>
      CONTENT_SECTION_TYPE.map(option => ({
        key: option,
        value: option,
        label: option
      }))
  },
  promoCodeDiscountType: {
    data: () =>
      PROMO_CODES_DISCOUNT_TYPES.map(option => ({
        key: option,
        value: option,
        label: option
      }))
  },
  newsletterSectionsColumn: {
    data: () =>
      _.map(CONTENT_SECTION_COLUMN, (value, key) => ({
        key: key,
        value: value,
        label: key
      }))
  },
  articleGroupings: {
    data: () =>
      ARTICLE_GROUPING.map(option => ({
        key: option,
        value: option,
        label: option
      }))
  },
  articleChoices: {
    url: props => `${BASE_URL}/articles/${props.productSlug}/choices/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'full_headline',
    data: props => Choices._getData(props)
  },
  sectionTemplates: {
    url: props =>
      `${BASE_URL}/newsletters/v2/content-sections/template-choices/`,
    keyField: 'value',
    valueField: 'value',
    labelField: 'title',
    data: props => Choices._getData(props)
  },
  feedbackVisibilityPage: {
    url: props => `${BASE_URL}/cms/pages/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    data: async props =>
      Choices._getData(props).then(data => {
        return data.filter(page =>
          ALLOWED_FEEDBACK_PAGES.includes(page.label.trim())
        );
      })
  },
  feedbackProductsAll: {
    url: props =>
      `${BASE_URL}/forms-dashboard/tracking-events/feedback/products/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'name',
    data: async props => Choices._getData(props)
  },
  feedbackDisplayOptions: {
    data: () =>
      FEEDBACK_DISPLAY_OPTIONS.map(option => ({
        key: option,
        value: option,
        label: option
      }))
  },
  allEvents: {
    url: props => `${BASE_URL}/crm/items/events/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    data: async props => Choices._getData(props)
  },
  upcomingEvents: {
    url: props => `${BASE_URL}/crm/items/events/?upcoming=True`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    data: async props => Choices._getData(props)
  },
  salesTerritories: {
    url: props => `${BASE_URL}/users/sales-territories/?paginate=false`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    data: async props => Choices._getData(props)
  },
  salesTerritoryStaffProfiles: {
    url: props => `${BASE_URL}/users/staff-members/?is_active=true`,
    keyField: 'id',
    valueField: 'id',
    labelFields: ['first_name', 'last_name'],
    extraDataFields: ['staff_profile'],
    data: async props => Choices._getData(props)
  },
  salesTerritoryStates: {
    url: props => `${BASE_URL}/users/sales-territories/states/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    extraDataFields: ['states'],
    data: async props => Choices._getData(props)
  },
  weeklySeasmTags: {
    data: () =>
      WEEKLY_SEASM_TAGS.map(option => ({
        key: option,
        value: option,
        label: option
      }))
  },
  globalProducts: {
    url: props => `${BASE_URL}/common/global-products/`,
    keyField: 'slug',
    valueField: 'slug',
    labelField: 'name',
    data: async props => Choices._getData(props)
  },
  corporateRssClients: {
    url: props => `${BASE_URL}/rss/corporate-clients/`,
    keyField: 'id',
    valueField: 'id',
    labelField: 'title',
    data: async props => Choices._getData(props)
  },
  trackedPriceSeries: {
    data: () =>
      PRICE_SERIES_OPEN_ACTION_TYPES.map(option => ({
        key: option,
        value: option,
        label: option
      }))
  },
  priceSerieStatuses: {
    data: () =>
      _.values(PriceSerieStatus).map(option => ({
        key: option,
        value: option,
        label: option
      }))
  },
  combinedPriceSeries: {
    url: props => `${BASE_URL}/prices/series/v2/combined/`,
    keyField: 'id',
    valueField: 'id',
    labelField: priceSerie => (
      <CombinedPriceSeriesLabelField priceSerie={priceSerie} />
    ),
    data: props => Choices._getData(props)
  }
};
