import React from 'react';
import _ from 'lodash';

import { Table } from 'semantic-ui-react';

import { Button, YesNo } from 'components';
import { formatDateTime } from 'utils';
import RegistrationAttemptDetailsModal from './RegistrationAttemptDetailsModal';
import PartialTrialPreferenceDetailsModal from './PartialTrialPreferenceDetailsModal';

class RegistrationAttemptRow extends React.Component {
  state = {
    isDetailsModalOpen: false,
    isPartialTrialPreferenceModalOpen: false
  };

  toggleDetailsModal = () => {
    this.setState(prevState => {
      const { item } = this.props;
      const preferenceId = _.get(item, 'partial_trial_preference', null);

      if (preferenceId) {
        return {
          isDetailsModalOpen: false,
          isPartialTrialPreferenceModalOpen: !prevState.isPartialTrialPreferenceModalOpen
        };
      }
      return {
        isDetailsModalOpen: !prevState.isDetailsModalOpen,
        isPartialTrialPreferenceModal: false
      };
    });
  };

  render() {
    const { item } = this.props;

    const {
      isDetailsModalOpen,
      isPartialTrialPreferenceModalOpen
    } = this.state;

    return (
      <>
        <Table.Row style={{ wordBreak: 'break-word' }}>
          <Table.Cell>{item.email}</Table.Cell>
          <Table.Cell>{formatDateTime(item.created_at)}</Table.Cell>
          <Table.Cell>{formatDateTime(item.updated_at)}</Table.Cell>
          <Table.Cell>{item.registration_type}</Table.Cell>
          <Table.Cell>{item.title}</Table.Cell>
          <Table.Cell>
            {item.first_name} {item.last_name}
          </Table.Cell>
          <Table.Cell>{item.job_title}</Table.Cell>
          <Table.Cell>{item.company_name}</Table.Cell>
          <Table.Cell>{item.phone}</Table.Cell>
          <Table.Cell>{item.city}</Table.Cell>
          <Table.Cell>{item.country}</Table.Cell>
          <Table.Cell>
            <YesNo value={item.finished} />
          </Table.Cell>
          <Table.Cell>
            {_.get(item.finished_by, 'first_name', 'N/A')}{' '}
            {_.get(item.finished_by, 'last_name', '')}
          </Table.Cell>
          <Table.Cell>
            <YesNo value={item.partial_trial_preference} />
          </Table.Cell>
          <Table.Cell>
            <Button onClick={this.toggleDetailsModal}>Show details</Button>
          </Table.Cell>
        </Table.Row>

        {isDetailsModalOpen && (
          <RegistrationAttemptDetailsModal
            item={item}
            close={this.toggleDetailsModal}
          />
        )}
        {isPartialTrialPreferenceModalOpen && (
          <PartialTrialPreferenceDetailsModal
            preferenceId={item.partial_trial_preference}
            close={this.toggleDetailsModal}
          />
        )}
      </>
    );
  }
}

export default RegistrationAttemptRow;
