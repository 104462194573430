import { Table } from 'semantic-ui-react';
import { formatDate } from 'utils';

import styles from '../../styles.module.css';

const PMTrialPreferencesTable = ({ preference }) => {
  const baseSubscription = preference.all_services[0];
  const otherServices = preference.all_services
    .slice(1)
    .filter(service => service !== '');

  return (
    <Table
      definition
      collapsing
      className={styles.tableStyle}
      style={{ marginTop: '0' }}>
      <Table.Row>
        <Table.Cell width={5}>Subscription Type</Table.Cell>
        <Table.Cell>{baseSubscription}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Other Services</Table.Cell>
        <Table.Cell>
          {otherServices.length === 0 && 'N/A'}
          {otherServices.length !== 0 && (
            <ul style={{ paddingLeft: '10px' }}>
              {otherServices.map((service, index) => (
                <li key={index}>{service}</li>
              ))}
            </ul>
          )}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Start Date</Table.Cell>
        <Table.Cell>{formatDate(preference.start_date)}</Table.Cell>
      </Table.Row>
    </Table>
  );
};

export default PMTrialPreferencesTable;
