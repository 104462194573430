import React from 'react';

import _ from 'lodash';
import { Header, Segment } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { Page, EmailVariables, LoginRequired } from 'components';
import {
  PERMISSIONS,
  PermissionsRequired,
  DisableableElement,
  hasPermission
} from 'permissions';

import { EmailTemplateUpdateForm } from './components';

import { emailTemplateDetail } from './sdk';

class EmailTemplateDetail extends React.Component {
  state = {
    emailTemplate: null,
    errors: null
  };

  componentDidMount() {
    this.fetchEmailTemplate();
  }

  fetchEmailTemplate = async () => {
    const { emailTemplateId } = this.props.match.params;

    const { data, errors, success } = await emailTemplateDetail(
      emailTemplateId
    );

    if (success) {
      this.setState({ emailTemplate: data });
    } else {
      this.setState({ errors });
    }
  };

  getDefaultEmailVariables = () => {
    const { emailTemplate } = this.state;

    if (
      emailTemplate &&
      emailTemplate.trigger === 'Partial Trial notification email'
    ) {
      // This is the list of variables that are available for the Partial Trial notification email,
      // as they are different from the default list.
      return [
        ['{{FIRST_NAME}}', 'Client First Name', true],
        ['{{LAST_NAME}}', 'Client Last Name', true],
        ['{{EMAIL}}', 'Client E-mail', true],
        ['{{COMPANY}}', 'Client Company', true],
        ['{{JOB_TITLE}}', 'Client Job Title', true],
        [
          '{{PARTIAL_TRIAL_SERVICES}}',
          'Partial Trial Services from Free Trial, listed as bullets',
          false
        ],
        ['{CURRENT_DATE}', 'Current date', true],
        ['{CURRENT_YEAR}', 'Current year', true]
      ];
    }

    return null;
  };

  render() {
    const { user, crumbs } = this.props;

    const { emailTemplate } = this.state;

    return (
      <Page user={user}>
        <Page.Body>
          <Page.Content crumbs={crumbs}>
            <Segment>
              <Header as="h1">Change Email Template</Header>
              {!_.isNil(emailTemplate) && (
                <DisableableElement
                  disabled={!hasPermission(user, PERMISSIONS.CHANGE_MAILSHOTS)}>
                  <EmailTemplateUpdateForm
                    data={emailTemplate}
                    fetchEmailTemplate={this.fetchEmailTemplate}
                  />
                </DisableableElement>
              )}
            </Segment>
            <Segment>
              <EmailVariables defaultData={this.getDefaultEmailVariables()} />
            </Segment>
          </Page.Content>
        </Page.Body>
      </Page>
    );
  }
}

export default LoginRequired(
  PermissionsRequired([PERMISSIONS.VIEW_MAILSHOTS])(
    withRouter(EmailTemplateDetail)
  )
);
