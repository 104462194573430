import { OpenModalButton } from 'components';

import ResultsPreviewTable from '../ResultsPreviewTable';
import { areAllSelectedPriceSet } from '../PriceSeriesCombineForm/utils';

export default function PreviewResultsModalButton({
  values,
  setFieldValue,
  productSlug
}) {
  return (
    <OpenModalButton
      disabled={!areAllSelectedPriceSet(values)}
      modal={{
        header: 'Data points combination preview',
        render: props => (
          <ResultsPreviewTable
            values={values}
            setFieldValue={setFieldValue}
            productSlug={productSlug}
          />
        )
      }}>
      Preview Combination
    </OpenModalButton>
  );
}
