import axios from 'axios';

import { LOGIN_API_URL, LOGOUT_API_URL, ME_API_URL } from './urls';

export const getConfig = (additionalConfig = {}) => {
  let config = {
    'Content-Type': 'application/json',
    withCredentials: true,
    ...additionalConfig
  };

  return config;
};

export const get = (url, params = {}, additionalConfig = {}) => {
  return axios.get(url, { ...getConfig(additionalConfig), params });
};

export const post = (url, data, params) => {
  return axios.post(url, data, { ...getConfig(), params });
};

export const put = (url, data, params) => {
  return axios.put(url, data, { ...getConfig(), params });
};

export const requestSdk = async sdk => {
  let result = { data: {}, errors: [], success: false };

  try {
    const response = await sdk();

    result.data = response.data;
    result.success = true;
  } catch (error) {
    if (!error.response) {
      result.errors = [{ message: error.toString(), status: null }];
      return result;
    }

    if (error.response.status === 401) {
      result.errors = [
        { message: 'Unauthorized', status: error.response.status }
      ];
    }

    if (error.response.status === 404) {
      result.errors = [{ message: 'Not found', status: error.response.status }];
    } else if (error.response.status === 412 && error.response.data.text) {
      result.errors = [
        { message: error.response.data.text, status: error.response.status }
      ];
    } else {
      result.errors = error.response.data.errors;
    }
  }

  return result;
};

// callSdkCache is a closure function and our sdk cache layer
// store already fetched data in cache dict
// the keys of the dict are unique urls
// accepts url & sdk and call requestSdk(url or sdk()) if the cache data is missing
export const callSdkCache = (function() {
  const cache = {};

  return async (url, { sdk = null } = {}) => {
    const cachedData = cache[url];

    if (cachedData) {
      return new Promise((resolve, reject) => {
        resolve({ success: true, data: cachedData, errors: null });
      });
    }
    const { success, data, errors } = await requestSdk(() =>
      sdk ? sdk() : get(url)
    );

    if (success) {
      cache[url] = data;
    }

    return new Promise((resolve, reject) => {
      resolve({ success, data, errors });
    });
  };
})();

export const login = async data => {
  return await requestSdk(() => post(LOGIN_API_URL, data));
};

export const logout = async () => {
  await requestSdk(() => post(LOGOUT_API_URL));
  window.location.reload();
};

export const me = async () => {
  return await requestSdk(() => get(ME_API_URL));
};
